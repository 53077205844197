<template>
  <b-card-body :class="{'loading': loading}">
    <tender-report-savings-and-overspending-table
      :data="data"
      :total="total"
      :period="filter.period"
      :alternative-calculation="filter.alternativeCalculation"
    />
  </b-card-body>
</template>

<script>
import TenderReportSavingsAndOverspendingTable from './TenderReportSavingsAndOverspendingTable';
import {tenderEconomyStatistic} from '@/services/api';
import Vue from "vue";

export default {
  name: 'TendersCategory',
  components: {
    TenderReportSavingsAndOverspendingTable,
  },
  props: {
    filter: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      data: [],
      total: {},
      loading: true,
    };
  },
  watch: {
    filter: {
      handler: function(newVal, oldVal) { // watch it
        console.log('Prop changed: ', newVal, ' | was: ', oldVal);
        this.tenderEconomyStatistic(newVal);
      },
      deep: true,
    },
  },
  mounted() {
    this.tenderEconomyStatistic(this.filter);
  },
  methods: {
    async tenderEconomyStatistic(data) {
      this.loading = true;
      const response = await tenderEconomyStatistic(this.$store.state.user.ownerId, Object.assign(data));
      if (response && response.status === 200) {
        this.total = response.data.total;
        this.data = response.data.items;
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
